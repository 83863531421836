import { $api } from "../utils/api";
import {
  emailRegex,
  getLanguageId,
  isNullOrEmpty,
  setResult,
  toggleBorder
} from "../utils/utils";

export function VacancyApply() {
  const formButton = document.getElementById("vacancy-apply-form-button");
  const fileInput = document.getElementById("file");
  const fileNameContainer = document.getElementById(
    "vacancy-apply-selected-file"
  );

  if (fileInput) {
    fileInput.addEventListener("change", event => {
      fileNameContainer.innerText = event.target?.files?.[0]?.name || "";
    });
  }

  if (formButton) {
    formButton.addEventListener("click", async () => {
      if (formButton.getAttribute("processing") === "true") {
        return;
      }

      formButton.setAttribute("processing", "true");

      const emailContainer = document.getElementById("vacancy-apply-email");
      const nameContainer = document.getElementById("vacancy-apply-name");
      const phoneContainer = document.getElementById("vacancy-apply-phone");
      const skillsContainer = document.getElementById("vacancy-apply-skills");
      const salaryContainer = document.getElementById("vacancy-apply-salary");
      const textContainer = document.getElementById("vacancy-apply-text");
      const fileContainer = document.getElementById("file");
      const fileLabel = document.getElementById("vacancy-file-upload-label");
      const acceptPrivacyPolicyLabel = document.getElementById(
        "privacy_policy_accepted_label"
      );

      const email =
        emailContainer?.value != null ? emailContainer?.value.trimEnd() : null;
      const emptyEmailAlert = document.getElementById("email-empty");
      const incorrectEmailAlert = document.getElementById("email-incorrect");
      const fillAllFields = document.getElementById("fill-all-fields");
      const acceptPrivacyPolicyField = document.getElementById(
        "accept-privacy-policy-error"
      );
      const privacyPolicyAccepted = document.getElementById(
        "privacy_policy_accepted"
      );
      const contactFormResultContainer = document.getElementById(
        "vacancy-apply-form-result-container"
      );

      emptyEmailAlert.classList.add("force-hide");
      incorrectEmailAlert.classList.add("force-hide");
      fillAllFields.classList.add("force-hide");
      acceptPrivacyPolicyField.classList.add("force-hide");
      contactFormResultContainer.classList.add("force-hide");

      const allowed = emailRegex(email);
      const emailIsNotEmpty = isNullOrEmpty(emailContainer);
      const nameIsNotEmpty = isNullOrEmpty(nameContainer);
      const phoneIsNotEmpty = isNullOrEmpty(phoneContainer);
      // const textIsNotEmpty = isNullOrEmpty(textContainer);
      const skillIsNotEmpty = isNullOrEmpty(skillsContainer);
      const salaryIsNotEmpty = isNullOrEmpty(salaryContainer);
      const fileIsNotEmpty = isNullOrEmpty(fileContainer);
      const privacyPolicyIsNotEmpty = isNullOrEmpty(
        privacyPolicyAccepted,
        "red",
        "red"
      );

      if (
        nameIsNotEmpty ||
        // textIsNotEmpty ||
        phoneIsNotEmpty ||
        skillIsNotEmpty ||
        fileIsNotEmpty ||
        salaryIsNotEmpty ||
        privacyPolicyIsNotEmpty
      ) {
        if (!fileContainer?.files?.length) {
          toggleBorder(fileLabel, false);
        } else {
          toggleBorder(fileLabel, true, "#ef9e67", "#fff");
        }

        if (privacyPolicyIsNotEmpty) {
          acceptPrivacyPolicyField.classList.remove("force-hide");
          toggleBorder(acceptPrivacyPolicyLabel, false);
        }

        fillAllFields.classList.remove("force-hide");
        formButton.setAttribute("processing", "false");
        return;
      }

      if (allowed && !emailIsNotEmpty) {
        const token = document.querySelector(
          '#vacancy-apply-form input[name="_token"]'
        )?.value;

        const form = document.getElementById("vacancy-apply-form");
        const params = new FormData(form);

        const languageId = getLanguageId() || "ge";

        const res = $.ajax({
          url: `/${languageId}/vacancy/apply`,
          data: params,
          type: "POST",
          contentType: false,
          processData: false,
          // ... Other options like success and etc
          success: function(data) {
            console.log(">>> data", data);

            setResult("#vacancy-apply-form-result", data.status, true);
            contactFormResultContainer.classList.remove("force-hide");
            acceptPrivacyPolicyField.classList.add("force-hide");
            toggleBorder(acceptPrivacyPolicyLabel, true);
            emailContainer.value = "";
            nameContainer.value = "";
            phoneContainer.value = "";
            skillsContainer.value = "";
            salaryContainer.value = "";
            textContainer.value = "";
            fileNameContainer.innerText = "";
            delete fileContainer.files;
          },
          error: function(error) {
            setResult(
              "#vacancy-apply-form-result",
              error?.responseJSON?.status || "",
              false
            );
            contactFormResultContainer.classList.remove("force-hide");
          }
        });
      } else if (emailIsNotEmpty) {
        emptyEmailAlert.classList.remove("force-hide");
      } else if (!allowed) {
        incorrectEmailAlert.classList.remove("force-hide");
        // setResult("#vacancy-apply-form-result");
      }

      formButton.setAttribute("processing", "false");
    });
  }
}
